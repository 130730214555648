import React from "react";

const About = () => {
  return (
    <div>
      <h1 style={{ color: "white", fontSize: "16px" }}>Почему выбирают именно нас</h1>
      <p style={{ color: "white", fontSize: "16px" }}>
        Компания Zavediauto предлагает целый комплекс услуг связанных с
        куплей-продажей автомобилей. Мы готовы помочь вам выгодно продать, купить или обменять ваш автомобиль. Опыт работы в данной сфере более 7 лет.
        Мы располагаем собственной площадкой для продажи автомобилей.
      </p>
      <p style={{ color: "white", fontSize: "16px" }}>Наша компания профессионально занимается продажей автозапчастей более 10 лет. Имеем
        большой склад б/у запчастей на европейские автомобили. Мы поможем вам подобрать и доставить новые запчасти.
      </p>
      <p style={{ color: "white", fontSize: "16px" }}>
        Остались вопросы? Свяжитесь с нами по телефону  <a style={{ color: "white", fontSize: "16px" }} href="tel:+79033281123">531-123</a>,  
        и мы с удовольствием дадим более
        расширенную консультацию
      </p>
    </div>
  );
};

export default About;
